import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate
import "./Toolbar.scss";

const Toolbar = () => {
  const navigate = useNavigate(); // Use useNavigate
  const location = useLocation();

  const handleGoBack = () => {
    navigate(-1); // Use navigate(-1) to go back
  };

  const handleGoForward = () => {
    navigate(1); // Use navigate(1) to go forward
  };

  const handleRefresh = () => {
    navigate(0); // Use navigate(0) to refresh
  };

  return (
      <div className="toolbar">
        <div className="toolbar__actables">
          <button type="button" onClick={handleGoBack}>
            <i className="fas fa-arrow-left"></i>
          </button>
          <button type="button" onClick={handleGoForward}>
            <i className="fas fa-arrow-right"></i>
          </button>
          <button type="button" onClick={handleRefresh}>
            <i className="fas fa-redo-alt"></i>
          </button>
          <div className="actables__url">
            https://yehyun.baby{location.pathname}
          </div>
        </div>
        <div className="toolbar__deco">
          <div className="deco__circle"></div>
        </div>
      </div>
  );
};

export default Toolbar;