import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.scss";
import Toolbar from "./components/Toolbar";
import Header from "./components/Header";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import data from "./data/data";

export default function App() {
  return (
      <BrowserRouter>
        <div className="App">
          <Toolbar />
          <Header />
          <Routes> {/* Switch를 Routes로 변경 */}
            <Route exact path="/" element={<Home />} />
            <Route exact path="/blog" element={<Blog data={data} />} />
            <Route path="/post/:id" element={<BlogPost data={data} />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<NotFound />} /> {/* 404 처리 */}
          </Routes>
        </div>
      </BrowserRouter>
  );
}