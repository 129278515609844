// data.js
const data = [
    {
        id: "10",
        title: "8/28 저녁 편지",
        createdAt: "2024/08/28",
        showAt: new Date(2024, 7, 28, 18, 0, 0).getTime(),
        content:
            "근데. 이거 저녁 편지인데 시간을 6시로 한건 좀 실수였던 것 같다\n" +
            "\n" +
            "ㅋㅋㅋㅋㅋㅋ\n" +
            "\n" +
            "6시쯤 되면 내가 저녁 먹을 시간이니까 무의식적으로 그렇게 정했던 것 같은데, 나중에 정식출시(?)를 하게된다면 밤 10시 10시반 정도로 바꿀게. 그때 아가가 가물가물~ 하면서 편지를 읽고 기분좋게 잘 수 있게 해주고파.  그러면 나는 코야~하는 예은이를 보면서 기분좋게 잘 수 있겠지. 좋은 감정의 전파야\n" +
            "\n" +
            "어느새 얼굴볼 날이 딱 다가왔네. 떨어져있던 시간이 짧고도 길었지! 예은이가 어떤 시간을 보냈을지 편지를 쓰는 시점의 나는 잘 모를수밖에 없지만.. 난 이걸 읽었을때 예은이가 푸욱~ 쉬고 나른한 일상들을 보내다가 어느새 시간이 지나서 허걱! 하고 놀랐으면 좋겠어. 기본적으로 예은이가 어디에 있건 행복했으면 좋겠어서 말이야.\n" +
            "\n" +
            "떨어져있는 것 때문에 걱정했던 8월도 어느새 다 끝나고 9월이 되었다. 날씨도 비가 많이와서 그렇지 예전만큼 덥지 않은 것 같아. 우리가 함께한 계절이 늘어날수록.. 추억도 더 쌓이고 감정이 더 깊어져서 너무 좋다. 근데 너무 좋아져서 큰일이다. 나중에는 와이셔츠 주머니에 아가를 넣고 출근해버릴지도 \n" +
            "\n" +
            "ㅋㅋ\n" +
            "\n" +
            "에은아, 나는 수요일 밤쯤이면 예은이가 엄청!!! 보고싶을거야. 그니까 얼른 코야하고 얼른얼른 회사도 마치고 볼 수 있었으면 좋겠어… ㅎㅎ 미리 너무 보고싶어 예은아!!!"
    },
    {
        id: "9",
        title: "8/28 점심 편지",
        createdAt: "2024/08/28",
        showAt: new Date(2024, 7, 28, 12, 0, 0).getTime(),
        content:
            "오늘은 헬스 안하는 점심!!!!\n" +
            "\n" +
            "회사를 다니면서 점심을 혼자 먹었던 날이 진짜 손에 꼽을 정도로 적었어. 진짜 한 7번도 안될 것 같아\n" +
            "\n" +
            "가끔 점심을 혼자 먹을때는 한시간 반동안 혼자서 조용히 생각도 하고 이것저것 밀린 일을 하거나 잠을 자거나 하면서 보낼 수 있으면서 좋긴 해. 하지만 점심에 헬스 안하면 난 지금쯤 진짜 굴러다녔겠구나… 하면서 매일 정신을 차려. 앞으로도 꾸준히 열심히 할게~ 아마 회사 그만두기 전까지는 반강제로 하게되지 않을까 싶어 ㅋㅋ 한번 들어오면 나갈 수 없는 곳에 들어왔으니까 … ㅎㅎ 그래도 할때는 힘들지만 나름 재밌어 아저씨들이 다들 성격이 좋으셔서\n" +
            "\n" +
            "반면 아가는 매번 혼자서 아침겸 점심을 먹었을거라고 생각하니까 혹여 쓸쓸하지는 않았을까 생각하게 되네. 아가가 불쌍해.. 그런 시간들은 후딱 보내버리고 이번 주말에 현수랑 재밌게 놀자. 맘마도 왕빵 맛있는거 잔뜩 같이 묵자 !!!! 대구갔을때 필름 카메라로 예은이를 찍어준게 너무 좋았어서 이번 주말에도 카메라를 들고 사진 찍으러 가고파 ㅎㅎ\n" +
            "\n" +
            "오늘은 어떤 하루가 될 것 같니? 나는 최대한 푹 쉬는 하루가 되었으면 좋겠어. 아가가 빈둥빈둥~ 하다보면 어느새 다시 옌뽀현뽀랑 노는 일상이 되었으면 좋겠어서 말이야. 어쨌든 오늘도 우당탕탕 오후 화이팅이야!"
    },
    {
        id: "8",
        title: "8/28 아침 편지",
        createdAt: "2024/08/28",
        showAt: new Date(2024, 7, 28, 8, 0, 0).getTime(),
        content:
            "아가!!!!!!! \n" +
            "\n" +
            "혼자 자는 잠은 어땠을까.\n" +
            "\n" +
            "바디필로우현수가 없어서 같이 잘때보다는 불편했을까?\n" +
            "\n" +
            "매일 예은이랑 꼬옥 안고 자면서 예전에는 하지 않았던 것들을 (불 키고 자기, 영상을 배경음악으로 해서 자기)하면서 자는 것도 나는 완전 적응이 되었거든. 예은이는 좀 어떠려나.\n" +
            "\n" +
            "난 잠을 자면 카페인을 마신 날에도, 술을 마신 날에도 푸욱 자서 수면의 질이 엄청 좋은 편인데 아가는 그게 아니라서 ㅜㅜ 항상 걱정이 된단다. 또 수면제는 새로운 약이 추가되는거니까 걱정이 많이 되기도 하고..\n" +
            "\n" +
            "어쨌든 현수랑 다시 꼬옥~ 안고 잘 날이 얼마 남지 않았으니 좀만 힘내고 나도 힘낼게 아가 없이 코야는 이젠 시러시러~"
    },
    {
        id: "7",
        title: "8/27 저녁 편지",
        createdAt: "2024/08/27",
        showAt: new Date(2024, 7, 27, 18, 0, 0).getTime(),
        content:
            "지금 이걸 보는 너는 침대에 누워있으려나? \n" +
            "\n" +
            "보통 예은이는 침대에 누워있는 아가니까 10시에는 침대에 누워있겠지… ㅎㅎ\n" +
            "\n" +
            "지금은 이 편지 사이트의 비밀에 대해서 조금 말해줄게.. \n" +
            "\n" +
            "우리 교환일기 사이트 만들기로했었잖아. 그런데 내가 ㅋㅋ 프론트엔드가 오랜만이고 생각보다 아가가 그려준 그림을 코드로 바꾸는게 어렵더라.. 그래서 연습삼아! 그리고 우리의 500일을 의미있게 축하하고, 지난번 아가 편지써준것도 생각나서 내가 유일하게 할 줄 아는 재주로 보답해주고 싶었어. 사실 내가 직접 그린 그림도 아니고 우리가 같이 한 무언가가 들어간 디자인은 아니어서 큰 감흥은 별로 없을지도? 싶긴 해.. 그래서 시작하고나서 생각보다 시간은 꽤 많이 썼는데 아가가 별로 감동하지 않으면 어쩌지 하고 많이 걱정도 했단다. 아무렴 어때! 나중에는 우리의 소중한 추억을 내가 할줄 아는 재주로 더 특별하게 기억할 수 있도록 하는 것을 많이 만들어보고 싶어. 그 시작이라고 생각해줘 ㅎㅎ\n" +
            "\n" +
            "요즘 예은이가 취미가 많이 생겼지! 스스로 행복할 수 있는 방법들, 그리고 더 단단한 예은이가 되어가는 것 같아서 나는 너무 좋아! 그렇게 되어가는 과정을 옆에서 바라볼 수 있고, 또 함께할 수 있는 것은 함께할 수 있는게 너무 좋아. 카메라로 사진 찍어주면서 우리가 할 수 있는 것이 더 늘어났다는 생각에 나는 무지 기뻤단다… 그리고 그걸 제외하고도 우리의 추억을 좀 더 예쁘게 담는 법을 고민하는 것은 생각보다 더 재미있고 행복한 일이더라. 앞으로도 사진 많이 찍자!!! 그리고 아가의 SNS에 한장 더 남길 수 있다면 그것도 넘 보람차고 말이야. 그리고 여유가 되면 또 다른 것도 같이 많이 많이 해보자. \n" +
            "\n" +
            "쓰다보니까 또 흐물흐물해지네… 이런 나라도 사랑해줘 예은아. 난 감자니까 흐물흐물해진김에 아예 흐믈감튀가 될게!!!! 사랑해 예은아!!!"
    },
    {
        id: "6",
        title: "8/27 점심 편지",
        createdAt: "2024/08/27",
        showAt: new Date(2024, 7, 27, 12, 0, 0).getTime(),
        content:
            "우당탕탕 점심이다!!!!\n" +
            "\n" +
            "아가는 어떤 하루를 보내고 있으려나?? 아직 침대에서 응애응애하고있으려나? 대구와서 맥날은 몇번이나 먹었을까?? 안먹지는 않았을 것 같아. 나도 오늘 점심은 맥날 먹을까… ㅎㅎ (응 … 헬스도시락 먹어야함)\n" +
            "\n" +
            "지난번에 집에 갔을때 잠 잘 못잤었던 것 같은데 요번에는 어떤지.. 알려줘. 아가를 건강 아가로 만드는 것도 내가 맡은 중요한 일이거든 (ㅋㅋ) \n" +
            "\n" +
            "네가 이걸 읽을때쯤에 나는 예은이가 너무 너무 많이 보고싶을 것 같아. 남들에게 여자친구 3일 못봐서 힘들다고 하면 웃기다고 하겠지만.. ㅎㅎ ㅋㅋ..  예은이가 옆에 없어서 찔끔 울었으려나? ㅋㅋㅋ 지난번에도 눈물 찔끔! 했어. 비밀이지만 예은이 편지 읽으면서 잉잉~도 했어. 예은이의 존재는 나에게 엄청 크고 소중해. 내가 경험하고 있는 특별한 감정들을 너도 느끼고 있다면 정말 좋을 것 같아.. 나는 이런 감정들을 네가 기분좋게 받아들일 수 있도록 또 내가 예은이 마음에 쏙 들 수 있도록 더 잘 행동할거야.\n" +
            "\n" +
            "나는 오늘 카페에 가서 스터디 공부를 하거나 지난번에 다이소 갔던 날 못갔던 야구 배팅장에 가볼까 해. 그렇게 한가지씩 해나가다 보면 예은이와 만나는 순간이 딱! 오겠지 ㅎㅎ.. \n" +
            "\n" +
            "맘마 맛나게 먹고 !! 오늘도 현수랑 냥냥 행복하자"
    },
    {
        id: "5",
        title: "8/27 아침 편지",
        createdAt: "2024/08/27",
        showAt: new Date(2024, 7, 27, 7, 0, 0).getTime(),
        content:
            "음… 아가가 이걸 읽을때쯤이면 나는 부스스 일어나 회사에 도착해있겠지\n" +
            "\n" +
            "예은이가 없을때는 더 회사가기가 힘든 것 같아. 일단 방이 훨씬 조용하기도 하고 먹여살릴 아가가 응애응애하고있어!!! 가 눈으로 안보여서 그런 것 같아 ㅋㅋ 그리고 아침에 일찍 가면 예은이를 일찍 본다는 생각에 더 일찍 가게 되는 것 같기도 하고 그래.\n" +
            "\n" +
            "아가가 졸려서 응애응애하고있으면 뽀뽀뽀 하고 나오고…. 물론 내가 욕심을 많이 부리다가 아가를 완전 깨워버린적도 많지만.. ㅎㅎ 예은이한테 사랑한다는 말을 듣고 우쭈쭈쭈 하는 걸 듣고싶은 욕심이 갈수록 커져가서 큰일이야. 그냥 예은이랑 함께하는 하루 루틴이 너무 좋고 행복해. \n" +
            "\n" +
            "아가가 오늘 무엇을 할지는 잘 모르겠지만 오늘도 좋은하루 되었으면 좋겠어!! 그리고 현수랑도 냥냥 이야기 많이하자 !!"
    },
    {
        id: "4",
        title: "500일 편지",
        createdAt: "2024/08/25",
        showAt: new Date(2024, 7, 25, 7, 0, 0).getTime(), // 2024년 8월 25일 오전 7시
        content:
            "안녕 예은아!!!\n" +
            "\n" +
            "와 우리 500일이다!!!!!! 예은이가 내 일상이 되고 같이 있는게 당연하게 된지가 한참 되어서 체감상 500일이 아니라 5000000일은 된 것 같아. 우리 울고 웃고를 참 많이 같이했지…. \n" +
            "\n" +
            "나 예은이랑 밤에 잘때 항상 한번은 꼭 안아달라고하잖아. 난 내 품에 꼭 안긴 예은이를 볼때 그 어느때보다 행복해. 그날 하루에 어떤일이 있었더라도 모든게 다 가라앉고 치유되는 느낌이거든. 너에게도 그런 행동이나 순간이 있니? 있으면 알려줘. 내가 너에게 무언가 해주면서 네가 기뻐하는 모습을 볼 때 나는 세상을 열심히 살아갈 기운이 든단다. \n" +
            "\n" +
            "만났을때 행복하게 해주는 것도 좋지만, 나는 너를 항상 행복한 사람으로 만들어주고싶어. 네가 나에게 그래주는 것 처럼 말이야. 우린 아직 어리다는 생각을 많이하게되는 요즘인데, 촬영하는 것도 그렇고 그림 그리는 것도 그렇고 조금씩 성장해가는 것 같아서 옆에서 바라보며 많이 뿌듯하고 (나 애기 키우나? ⇒ 맞음) 예은이가 예은이 스스로가 어떤사람인지 알아가고, 스스로 행복하는 법을 알아가는 과정에서 도움을 많이 주고 싶어. 그게 우리가 오래오래 함께 행복할 수 있는 길이라는 생각이 들어.\n" +
            "\n" +
            "물론 매일 만날거지만 9월부터는 예은이도 학교를 가고 좀 바빠지면서 조금은 우리의 패턴이 바뀔수도 있겠다. 나와의 관계에서 무언가를 해줘야한다는 것 때문에 부담 느끼지 않았으면 좋겠어. 학교나 촬영같은걸로 다른 일이 있으면 같이 가서 도와준다거나 내가 아는 선에서 (프로프로그래머) 도움을 준다거나 아니면 옆에서 그저 바라봐주고 맛난걸 먹여준다거나… 그런 식으로 함께할수도 있으니까 말이야 ~ 우리 둘이서 뭔가 특별한걸 하지 않아도 네가 사랑받구 있구나, 특별한 삶을 살고 있구나 하는 기분을 느낄 수 있도록 해주고싶어. \n" +
            "\n" +
            "편지 내가 오랜만에 쓰지.. 그래서 그런가 쓰다보니까 자꾸 동물의 숲 주민 편지 말투로 쓰게되는데 (ㅋㅋ) 앞으로는 편지 더 많이 주고받고.. 글로 내생각 많이 전달해줄게. \n" +
            "\n" +
            "사랑해 예은아. 500일을 넘어서 1000일 5000일 50000일.. 계속해서 나랑 함께해줘. 그리고 그렇게 우리가 함께하는 시간은 행복함으로 가득찰 수 있도록 내가 많이 노력할게. 너도 나를 계속 사랑해줘 예은아 \n" +
            "\n" +
            "사랑한다는 말로 부족해 예은아. \n" +
            "\n" +
            "행복한 500일을 보내는 현수가!!!"
    },
    {
        id: "3",
        title: "8/26 아침 편지",
        createdAt: "2024/08/26",
        showAt: new Date(2024, 7, 26, 8, 0, 0).getTime(), // 2024년 8월 26일 12시 30분
        content:
            "아가!!!! \n" +
            "\n" +
            "상쾌한 아침일까 아니면… 기차타고 늦게 도착한 탓에 늦잠 자다 일어나서 보게되었을까?\n" +
            "\n" +
            "병원은 늦지않게 갔으려나 몰라!!\n" +
            "\n" +
            "현수랑 떨어져서 지내는 첫 날 아침이라고 울면 안돼!! (안울음\n" +
            "\n" +
            "난 좀 적적할 것 같아. 하지만 이겨내고 아가랑 여행갈 생각하며 이겨낼거야\n" +
            "\n" +
            "휴가도 미리 승인 받을거야!!\n" +
            "\n" +
            "그리고 박도현, 이태겸 님과 빵도 좀 먹고.. 월급 루팡하다가 헬스하러 가겠지 ㅎㅎ …" +
            "\n" +
            "아가도 오늘 무슨 하루를 보낼지 현수에게 알려줘~~~"
    },
    {
        id: "2",
        title: "8/26 점심 편지",
        createdAt: "2024/08/26",
        showAt: new Date(2024, 7, 26, 12, 0, 0).getTime(),
        content:
            "첫 날이 꾸벅꾸벅 지나고있네 아가.\n" +
            "\n" +
            "아가랑 대구에서 놀았던 기억이 아직도 생생해 (ㅋㅋ 아직 2주일도안지남)\n" +
            "\n" +
            "어떻게 보면 대구는 도시가 정말 잘 정돈되어있어서 혼자 걸으면 쓸쓸할수도 있겠다 싶거든 \n" +
            "\n" +
            "현수랑 또 대구에 놀러가서 같이 냥냥 걷자. \n" +
            "\n" +
            "나에게는 여행이겠지만 너에게는 일상인 대구가 그래서 마음에 들어.\n" +
            "\n" +
            "어떻게 보면 나에게 안좋은 기억일 수 있는 대구가 너로인해서 참 좋은곳으로 기억에 남아있구나\n" +
            "\n" +
            "ㅎㅎ\n" +
            "\n" +
            "아 참 점심 맛난거 먹어!!! 대구가면 제일 좋은게 그거잖아 ㅎㅎ "
    },
    {
        id: "1",
        title: "8/26 저녁 편지",
        createdAt: "2024/08/26",
        showAt: new Date(2024, 7, 26, 18, 0, 0).getTime(),
        content:
            "현수없이 보내는 첫 저녁은 어땠으려나? \n" +
            "\n" +
            "어떤 일을 했을까 궁금하다. 병원 다녀온 것 제외하고는 널부렁~ 하면서 하루를 보냈을까? 아니면 할머니댁에도 다녀오고 여기저기 다녀오면서 힘겨운 하루를 보냈을까?\n" +
            "\n" +
            "병원은 잘 다녀왔을지 그게 제일 걱정돼 나는.. \n" +
            "\n" +
            "아가가 나 없이 자서 잠을 뒤척이지는 않을까 걱정되네 ㅠㅠ…. \n" +
            "\n" +
            "아! 맞아 우리가 같이 잘때 잠이 안오면 산책가는거 난 너무 좋았어서 또 가고 싶거든..\n" +
            "\n" +
            "그니까 발로 뻥! 차서라도 깨워서 같이가자..\n" +
            "\n" +
            "난 잠돼지라 잠을 너무 잘자거든 .. \n" +
            "\n" +
            "이걸 보면 현수랑 전화하자. 그리고 냥냥 무슨일이 있었는지 이야기해줘. \n" +
            "\n" +
            "난 혼자 자면 쓸쓸하단 말이야!!! 아가랑 냥냥이야기하다가 스르르자고파~"
    }
];

export default data;