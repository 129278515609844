import "./BlogList.scss";
import BlogItem from "./BlogItem";

const BlogList = ({ data }) => {
    const currentTime = new Date().getTime(); // 현재 시간을 epoch time으로 가져오기

    return (
        <>
            <p>아침 편지는 8시! 점심 편지는 12시! 저녁 편지는 18시!</p>
            <br/>
            <ul className="blog-list">
                {data
                    .filter(el => el.showAt <= currentTime) // showAt 시간이 지난 편지만 필터링
                    .map(el => (
                        <BlogItem item={el} key={el.id} showContent={false}/>
                    ))}
            </ul>
        </>
    );
};

export default BlogList;