import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./NotFound.scss";

const NotFound = () => {
    const navigate = useNavigate(); // Use useNavigate

    const handleClick = () => {
        navigate("/");
    };

    return (
        <main className="not-found">
            <h1>404</h1>
            <p>Opps! Can't find the page.</p>
            <button className="not-found-btn" type="button" onClick={handleClick}>
                Go To Homepage
            </button>
        </main>
    );
};

export default NotFound;