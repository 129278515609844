import "./About.scss";
import picture from "../assets/비누-removebg-preview.png";

const About = () => {
    return (
        <main className="about">
            <h3>예현의 500일을 기념하고 현수없는 3일을 버텨야하는 예은이를 위해</h3>
            <img src={picture} alt="picture" />
            <p style={{textAlign: 'left'}}>
                아가!!!!!!!
                <br/>
                3일간 나 없어도 외로워 하지마!!!!!!!!!!!!!
                <br/>
                그런 의미로 만들어봤어!!!!!!!!
                <br/>
                사랑해!!!!
            </p>
        </main>
    );
};

export default About;
