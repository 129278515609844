import React, { useState, useEffect } from 'react';
import { Line } from 'rc-progress';

function ProgressBar() {
    const [progress, setProgress] = useState(0);
    const [strokeColor, setStrokeColor] = useState("#2db7f5"); // 초기 색상 설정

    useEffect(() => {
        const startDate = new Date(2024, 7, 25, 22, 30); // 2024년 8월 25일 22시 30분
        const endDate = new Date(2024, 7, 29, 11, 30); // 2024년 8월 29일 11시 30분
        const now = new Date();

        const totalDuration = endDate - startDate;
        const elapsedDuration = now - startDate;

        const calculatedProgress = Math.min(
            Math.round((elapsedDuration / totalDuration) * 100),
            100
        );

        setProgress(calculatedProgress);

        const intervalId = setInterval(() => {
            const now = new Date();
            const elapsedDuration = now - startDate;
            const newProgress = Math.min(
                Math.round((elapsedDuration / totalDuration) * 100),
                100
            );
            setProgress(newProgress);
        }, 1000); // 1초마다 업데이트

        return () => clearInterval(intervalId);
    }, []);

    // 새로고침 시 색상 변경
    useEffect(() => {
        const randomColor = () => {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        };

        setStrokeColor(randomColor());
    }, []);

    return (
        <Line percent={progress} strokeWidth={8} strokeColor={strokeColor} style={{width:'90%'}}/>
    );
}

export default ProgressBar;