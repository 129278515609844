import "./Home.scss";
import picture1 from "../assets/멈머__1_-removebg-preview.png";
import ProgressBar from "../components/ProgressBar";
import {useState, useEffect} from "react";

const Home = () => {
    const [showProgressBar, setShowProgressBar] = useState(false);

    useEffect(() => {
        const checkTime = () => {
            const now = new Date();
            const targetTime = new Date(2024, 7, 25, 22, 30); // 2024년 8월 25일 22시 30분

            if (now >= targetTime) {
                setShowProgressBar(true);
            }
        };

        checkTime(); // 초기 확인
        const intervalId = setInterval(checkTime, 60000); // 1분마다 확인

        return () => clearInterval(intervalId);
    }, []);

    return (
        <main className="home">
            <img src={picture1} alt="test"/>
            {showProgressBar ? (
                <div>
                    <ProgressBar/>
                    <p>다시 만날 때까지!</p>
                </div>
            ) : (
                <h3>10시 반에 다시 봐줘!!</h3>
            )}
        </main>
    );
};

export default Home;