import { useParams } from "react-router-dom";
import "./BlogPost.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Firebase 설정 정보 (제공된 firebaseConfig 사용)
const firebaseConfig = {
    apiKey: "AIzaSyBPXYi3YWf098OUJanp9zeTdmfmq5RhJVQ",
    authDomain: "yehyun-1d694.firebaseapp.com",
    projectId: "yehyun-1d694",
    storageBucket: "yehyun-1d694.appspot.com",
    messagingSenderId: "153490749293",
    appId: "1:153490749293:web:e143995fb8e14011478c64",
    measurementId: "G-9QFWELEHXB"
};

// Firebase 초기화 및 analytics, firestore 설정
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

const BlogPost = ({ data }) => {
    const { id } = useParams();
    const target = data.filter((el) => el.id === id)[0];
    const [ip, setIP] = useState("");

    const getData = async () => {
        try {
            const res = await axios.get("https://api.ip.pe.kr/json/");
            setIP(res.data.ip);

            // Cloud Firestore의 'ip' 컬렉션에 IP 주소 및 타임스탬프 저장
            const ipCollectionRef = collection(db, 'ip');
            const now = new Date();
            const formattedTimestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;


            await addDoc(ipCollectionRef, {
                ipAddress: res.data.ip,
                timestamp: formattedTimestamp
            });

        } catch (error) {
            console.error("Error fetching IP or saving to DB:", error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <main className="post">
            <h1>{target.title}</h1>
            <p className="post__date">{target.createdAt}</p>
            <p className="post__content">{target.content}</p>
        </main>
    );
};

export default BlogPost;