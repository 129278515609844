import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import "./BlogItem.scss";

const BlogItem = ({ item, showContent }) => { // showContent prop 추가
    return (
        <li className="blog-item">
            <Link to={"/post/" + item.id}>
                <h3>{item.title}</h3>
                <p className="blog-item__date">{item.createdAt}</p>
                {showContent && <ReactMarkdown>{item.content}</ReactMarkdown>} {/* 조건부 렌더링 */}
            </Link>
        </li>
    );
};

export default BlogItem;