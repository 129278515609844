import { NavLink, useNavigate } from "react-router-dom"; // useNavigate import

import "./Header.scss";
import "../pages/Blog";


const Header = () => {
  const navigate = useNavigate(); // useHistory -> useNavigate

  const handleGoToHome = () => {
    navigate("/"); // history.push -> navigate
  };

  return (
      <header>
      <span className="logo" onClick={handleGoToHome}>
        YeHyun
      </span>
        <ul className="page-links">
          <li>
            <NavLink exact to="/" activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/blog" activeClassName="active">
              Letters
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active">
              About
            </NavLink>
          </li>
        </ul>
      </header>
  );
};

export default Header;